import DemoFormSection from "components/common/DemoFormSection";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoSlider from "components/common/InfoSlider";
import IntegrationSlider from "components/common/IntegrationSlider";
import BenefitRows from "components/ITPage/BenefitRows";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function Console() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "CONSOLE",
        title: "Streamline Your Physical Security On a Unified Cloud Platform",
        description:
          "Meet your central nervous system for physical security management. With native remote access on a unified cloud platform, it’s easy to manage unlimited cameras, users, devices, locations, and solutions at scale. ",
        image: data.headerImage,
        button1: {
          text: "Request Demo",
        },
      },
      benefitRows: [
        {
          preTitle: "MODERNIZE AND CENTRALIZE",
          title: "Get Comprehensive Visibility Anytime, Anywhere ",
          description:
            "Get the complete picture of activity across all locations and protect your organization with confidence. Remotely view and manage cameras, sensors, and solutions from one unified cloud platform to streamline your physical security management.",
          specs: [
            { title: "Unlimited", description: "locations and users" },
            {
              title: "99.9%",
              description: "uptime for unlimited devices and locations",
            },
          ],
          image: data.benefitImage1,
        },
        {
          preTitle: "SAVE TIME",
          title: "Find Footage in Minutes, Not Hours",
          description:
            "Say goodbye to manual scrubbing and speed up investigations with smart search. With multi-camera investigations and automatically indexed events, it’s easy to jump to relevant video segments and quickly find persons-of-interest, access events, and more. ",
          specs: [
            { title: "90%", description: "faster incident investigations" },
            {
              title: "150%",
              description: "productivity increase for all system users*",
            },
          ],
          note: "*Rhombus case studies and customer surveys",
          image: data.benefitImage2,
        },
        {
          preTitle: "PROACTIVE MONITORING",
          title: "Stay Ahead of Threats with Real-Time AI Alerts",
          description:
            "Proactively identify and respond to potential threats with real-time alerts based on AI analytics. Rhombus monitors your spaces 24/7 and automatically sends live alerts with synchronized footage when suspicious activity is detected for fast, informed response.",
          specs: [
            { title: "18x", description: "faster incident resolution" },
            {
              title: "24/7",
              description: "automatic monitoring",
            },
          ],
          image: data.benefitImage3,
        },
      ],
      infoSlides: [
        {
          tabTitle: "Video Walls",
          preTitle: "BETTER LIVE MONITORING",
          title: "Video Walls",
          description:
            "Enhance live monitoring and see the whole picture at a glance with custom video walls. View livestreams from multiple cameras on a single display, securely share walls, and create a control room experience from anywhere.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Smart Search",
          preTitle: "FASTER INVESTIGATIONS",
          title: "Smart Search",
          description:
            "Accelerate investigations by pinpointing footage based on key details. Search using AI analytics and find activity based on human movement, facial recognition, license plate recognition, audio, color search, and more. ",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Easy Sharing",
          preTitle: "COMMUNICATE & COLLABORATE",
          title: "Easy Sharing",
          description:
            "Collaboration is easy, fast, and flexible. Securely share video clips, livestreams, and historical streams with anyone—send footage via text, email, and URL and view on any device with no login required.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Unlimited Users",
          preTitle: "MANAGE END-USERS",
          title: "Unlimited Users",
          description:
            "Seamlessly add users, devices, and locations with no tiers or seat limits. Gain granular control over user permissions with custom roles and access types that make it easy to scale vertically within your organization.",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Open & Integrated",
          preTitle: "Your Security, Your Way",
          title: "Open & Integrated",
          description:
            "Enjoy the freedom to customize your system to your organization’s unique needs. Leverage Rhombus’ open API to build custom functionality and set up powerful integrations with your favorite third-party systems.",
          image: data.infoSlide5,
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus can transform your physical security.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus Console – Enterprise Security Management</title>
          <meta
            name="description"
            content="Modern security management with the cloud-based Rhombus Console and Mobile App. Learn how you can manage cameras, sensors, and more from one platform."
          />
        </Helmet>
        <HeroLayout1 data={pageData.headerSection} bottom />
        <BenefitRows data={pageData.benefitRows} />
        <InfoSlider
          data={pageData.infoSlides}
          color="var(--nuetral-100)"
          title="Powerful and User-Friendly Security Management"
        />
        <IntegrationSlider />
        <DemoFormSection data={pageData.formSection} />
        <TrialBanner />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/console/img/console-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefitImage1: file(
        relativePath: { eq: "components/ITPage/img/modernize-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage2: file(
        relativePath: {
          eq: "components/ai/updated/img/facial-recognition-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage3: file(
        relativePath: { eq: "components/alarms/updated/img/drawer3-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/console/img/video-walls-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/ITPage/img/stream-line-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/industries/updated/img/government/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/console/img/end-users-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/console/img/integrations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
